



















import Vue from 'vue';
import rsWizardStep from '@/src/components/processes/rs-wizard-step.vue';
import CreateMembershipRequestViewModel from '../../../../../services/viewModel/resource/CreateMembershipRequestViewModel';

export default Vue.extend({
  components: { rsWizardStep },
  props: {
    value: { type: Object, default: undefined },
  },
  data: () => ({
  }),
  computed: {
    innerValue: {
      get(): CreateMembershipRequestViewModel {
        return this.value ?? {};
      },
      set(value: CreateMembershipRequestViewModel) {
        this.$emit('input', value);
      },
    },
  },
});
